html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body > h1 {
  font-weight: 100;
  font-size: 60pt;
  margin: -19px 0;
  text-align: right;
  color: #777;
}  

hr {
  margin-right: 60px;
}

.sliders {
  position: relative;
  width: 90%;
  border: 1px solid #aaa;
  border-radius: 8px;
  margin: 40px 0;
  padding: 15px;
}

.sliders > h1 {
  position: absolute; 
  top: -1.2ex;

  font-weight: 100;
  font-size: 30pt;
  background: white;
  margin: 0;
  padding: 0 0.5em;
  display: inline-block;

  /*text-align: left;*/
  /*margin-left: 10px;*/
  /*margin-top: -1ex;*/
}

#swatch {
  width: 100%;
  text-align: center;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 50pt;
}
